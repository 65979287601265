<template>
  <div>
    <ul>
      <li>ข้อ 1. จำนวนผู้มีสิทธิเลือกตั้งในบัญชีรายชื่อผู้มีสิทธิเลือกตั้ง</li>
      <li>ข้อ 2. จำนวนผู้มีสิทธิเลือกตั้งที่มาแสดงตนขอรับบัตรเลือกตั้ง</li>
      <li>ข้อ 3. จำนวนบัตรเลือกตั้งที่ได้รับมาทั้งหมด</li>
      <li>ข้อ 4. จำนวนบัตรเลือกตั้งที่ใช้ลงคะแนนเลือกตั้ง</li>
      <li>ข้อ 5. จำนวนบัตรดี</li>
      <li>ข้อ 6. จำนวนบัตรเสีย</li>
      <li>ข้อ 7. จำนวนบัตรเลือกตั้งที่ทำเครื่องหมายไม่เลือกผู้สมัครผู้ใด</li>
      <li>ข้อ 8. จำนวนบัตเลือกตั้งที่เหลือ</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CardDescription'
}
</script>

<style>

</style>